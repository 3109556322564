import React from 'react';
import {createStyles, makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import {Grid, Menu} from '@material-ui/core';
import {useAuth0} from "../Auth";
import {config} from "../config";
import {connect, ConnectedProps} from "react-redux";
import {ApplicationState} from "../store";
import {withStyles} from "@material-ui/styles";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import {DropdownItem} from "reactstrap";
import FrontStepsLogo from "../assets/icons/fs-pay-icon-green.svg";


const useStyles = makeStyles(() =>
    createStyles({
        header: {
            height: "65px",
            backgroundColor: "#243141",
            boxShadow: "0 4px 6px 0 rgba(0,0,0,0.2)"
        },
        toolbar: {
            height: "80px",
            paddingLeft: "0"
        },
        caption: {
            fontSize: "12px"
        },
        menuButton: {
          textTransform: "capitalize",
          backgroundColor: "#243141 !important",
          color: "#ffffff !important"
        },
        menuContainer: {
            display: "flex",
            height: "80px",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 20px",
            minWidth: "200px",
            width: "100%",
            borderLeft: "1px solid rgba(255, 255, 255, 0.3)",
            borderRight: "1px solid rgba(255, 255, 255, 0.3)"
        },
        menuSelect: {
            backgroundColor: "#15212F !important",
            color: "#A9C2D6 !important",
            fontWeight: 700,
            fontSize: '13px',
            lineHeight: '20.56px',
            padding: '16px 12px 16px 12px',
            textDecoration: 'none',
            textTransform: 'uppercase',
            marginLeft: '15px',
            letterSpacing: '2px',
            "&:hover": {
              color: "#FFFFFF !important"
            }
        },
        title: {
            flexGrow: 1,
            fontSize: '14px',
            fontWeight: 700,
            lineHeight: '20.56px',
            color: '#fff',
            textTransform: 'uppercase',
            letterSpacing: '3px'
        },
    }),
);

const CustomMenu = withStyles({
  paper: {
    padding: "10px",
    zIndex: 99,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#15212F !important',
    borderBottomLeftRadius: '12px',
    borderBottomRightRadius: '12px',
    boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.2)',
    position: 'fixed',
    width: '300px',
    height: 'fit-content',
    top: '40px'
  },
})((props: any) => (
  <Menu
    elevation={1}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const NavMenu = (props: NavMenuProps) => {
  const classes = useStyles();
  const { userInfo } = props
  const { isAuthenticated, logout } = useAuth0();
  const [helpEL, setHelpEL] = React.useState(null);
  const [profileEL, setProfileEL] = React.useState(null);
  
  const verifiedAmbassadors = config.appLinks.verifiedAmbassadors;
  
  const getGlobalNavLinks = (): {url: string; label: string}[] => {
    return [
      { url: verifiedAmbassadors, label: 'Verified Ambassadors' }
    ];
  };
  
  function getUserInitials() {
    if (userInfo.name) {
      return userInfo.name.split(' ').map((n: string) => n[0]).join('');
    } else if (userInfo.email) {
      return userInfo.email.split(' ').map((n: string) => n[0]).join('');
    }
    return 'FS';
  }
  
  return (
    <React.Fragment>
      <div>
        {isAuthenticated && (
          <AppBar position="fixed" className={classes.header}>
            <Toolbar className={classes.toolbar}>
              <global-nav isMobile={'false'} links={JSON.stringify(getGlobalNavLinks())} />
              <Grid container justifyContent="flex-start" alignItems="center" spacing={5}>
                <Grid item className="d-none d-md-block">
                  <Typography variant="h6" className={classes.title}>
                    <img
                      alt="Payments Icon"
                      src={FrontStepsLogo}
                      className="payments-icon"/>
                    <span>PAYMENTS</span>
                  </Typography>
                </Grid>
                <Grid item className="ms-auto">
                  <Grid container>
                    <Grid item>
                      <IconButton
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={(event: any) => setHelpEL(event.currentTarget)}
                        className={classes.menuButton}
                      >
                        <HelpOutlineIcon style={{marginTop: 4}} fontSize="large"/>
                      </IconButton>
                      <CustomMenu
                        keepMounted
                        anchorEl={helpEL}
                        open={Boolean(helpEL)}
                        onClose={() => setHelpEL(null)}
                      >
                        <DropdownItem className={classes.menuSelect} tag="a" href="/FAQ"
                                      target="_blank">FAQs</DropdownItem>
                      </CustomMenu>
                    </Grid>
                    <Grid item>
                      <IconButton
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        color="inherit"
                        onClick={(event: any) => setProfileEL(event.currentTarget)}
                      >
                        <div className='loggedIn-user-circle' >
                          <p>{getUserInitials()} </p>
                        </div>
                      </IconButton>
                      
                      <CustomMenu
                        keepMounted
                        anchorEl={profileEL}
                        open={Boolean(profileEL)}
                        onClose={() => setProfileEL(null)}
                      >
                        <DropdownItem
                          disabled={true}
                          className={classes.menuSelect}
                          tag={'p'}
                        >
                            {userInfo.userCompany ? userInfo.userCompany.name : userInfo.name}
                        </DropdownItem>
                        <DropdownItem
                          className={classes.menuSelect}
                          onClick={() => logout({returnTo: config.redirectUri})}
                          >LOGOUT
                        </DropdownItem>
                      </CustomMenu>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>)}
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = ({userInfo, router}: ApplicationState) => ({
  userInfo,
  location: router.location
})

const connector = connect(mapStateToProps)

type NavMenuProps = ConnectedProps<typeof connector>

export default connector( NavMenu )
