import { config } from "../config"
import httpService from "./HttpService"
import { DatatableLockboxFileRequestInterface } from "../interfaces/lockboxfiles.datatable.request.interface";
import { DatatableResponseInterface } from "../interfaces/datatable.response.interface";
import fileDownload from "js-file-download";
import { getURLSearchParams } from "../utils/helpers";
const LOCKBOXFILES_URL = `${config.baseUrl}${config.suffixApi}${config.versionApi}/reporting/lockboxFiles`


export const getLockboxFiles = (request: DatatableLockboxFileRequestInterface):Promise<DatatableResponseInterface> => {
    return httpService.get<DatatableResponseInterface>(LOCKBOXFILES_URL,getURLSearchParams(request))
  }

export const downloadLockboxFile = (blobFileId: string) => {
  httpService.get(`${LOCKBOXFILES_URL}/download`, getURLSearchParams({blobFileId : blobFileId}))
    .then((data: any) => {
      const nameFileArray = blobFileId.split('/');
      const managementCompanyId = nameFileArray[0];
      const fileNameWithExt = nameFileArray[2]; // e.g. "02072025.txt"
      
      // Extract the extension (e.g. "txt" or "csv")
      const extension = fileNameWithExt.substring(fileNameWithExt.lastIndexOf('.') + 1);
      const baseName = fileNameWithExt.substring(0, fileNameWithExt.lastIndexOf('.'));
      
      // Build a friendly file name. Example: "MC5195_02072025.txt"
      const finalFileName = `MC${managementCompanyId}_${baseName}.${extension}`;
      
      let mimeType: string;
      switch (extension.toLowerCase()) {
        case 'csv':
          mimeType = 'text/csv';
          break;
        case 'txt':
          mimeType = 'text/plain';
          break;
        default:
          mimeType = 'application/octet-stream';
          break;
      }
      fileDownload(data, finalFileName, mimeType);
    });
}
