import React from 'react';
import {useAuth0} from "../Auth";
import {config} from "../config";
import {connect, ConnectedProps} from "react-redux";
import {ApplicationState} from "../store";
import history from "../utils/history";
import {Dispatch} from "redux";
import * as homeownersActions from "../actions/homeowners";
import * as transactionsActions from "../actions/paymentTransactions";
import moment from "moment";
import {updateDashboardUserFilter} from "../actions/dashboardUser";
import {SendPaymentsTo} from "../interfaces/user.company.interface";

/**
 * FrontSteps Employees Options
 * - Payments
 * - Homeowners
 * - Payouts
 * - View Dashboard Users
 * - Lockbox Files
 * - Associations onboarding
 * - Representative Profile
 * - Tax Forms
 * - Reports
 * - Release Notes (Only if the user email is in the teamEmails list)
 *
 * * Full Access Options
 *        * - Payments
 *        * - Homeowners
 *        * - Payouts
 *        * - View Dashboard Users
 *        * - Lockbox Files
 *        * - Associations onboarding
 *        * - Tax Forms
 *
 * * Dashboard User Options
 *        * - Payments
 *        * - Homeowners
 *        * - Payouts
 *        * - View Dashboard Users
 *        * - Lockbox Files
 *        * - Tax Forms
 *        * - Associations onboarding (+ onboard user)
 *        * - Reports (+ reports user)
 * * Onboarding User Options
 *        * - Associations onboarding
 * * Reports User Options
 *        * - Reports
 */

const SideMenu = (props: SideMenuProps) => {
  const { userInfo, location, clearFiltersData } = props
  const { isAuthenticated } = useAuth0();
  const emails = config.teamEmails?.toLowerCase().split(',') || [];
  let menu = [];
  
  if (!!userInfo && isAuthenticated) {
    
    if (userInfo?.isFSEmployee) {
      menu.push({
        label: "Payments",
        path: "/Payments"
      });
      menu.push({
        label: "Homeowners",
        path: "/Homeowners"
      });
      menu.push({
        label: "Payouts",
        path: "/Payouts"
      });
      menu.push({
        label: "View Dashboard Users",
        path: "/DashboardUser"
      });
      
      if (!userInfo.userCompany || SendPaymentsTo.LockBoxFile === userInfo?.userCompany?.sendPaymentsTo) {
        menu.push({
          label: "Lockbox Files",
          path: "/LockboxFiles"
        });
      }
      
      if (!userInfo.userCompany) {
        menu.push({
          label: "Associations onboarding",
          path: "/Onboarding"
        });
        
        menu.push({
          label: "Representative Profile",
          path: "/Representative"
        });
      }
      
      menu.push({
        label: "Tax Forms",
        path: "/TaxForms"
      });
      
      menu.push({
        label: "Reports",
        path: "/Reports"
      });
      
      if (emails.includes(userInfo?.email?.toLowerCase() || '')) {
        menu.push({
          label: "Release Notes",
          path: "/Admin"
        });
      }
      
      // Full Access is for backward compatibility with Auth0 roles
    } else if (userInfo.fullAccessProfile) {
      menu.push({
        label: "Payments",
        path: "/Payments"
      });
      menu.push({
        label: "Homeowners",
        path: "/Homeowners"
      });
      menu.push({
        label: "Payouts",
        path: "/Payouts"
      });
      menu.push({
        label: "View Dashboard Users",
        path: "/DashboardUser"
      });
      if (!userInfo.userCompany || SendPaymentsTo.LockBoxFile === userInfo?.userCompany?.sendPaymentsTo) {
        menu.push({
          label: "Lockbox Files",
          path: "/LockboxFiles"
        });
      }
      menu.push({
        label: "Associations onboarding",
        path: "/Onboarding"
      });
      menu.push({
        label: "Tax Forms",
        path: "/TaxForms"
      });
    } else {
      
      // Adding conditions for the new role implementation
      // Dashboard User Only
      if (userInfo.isDashboardUser || (
        !userInfo.isOnboardUser
        && !userInfo.isReportsUser
        && !userInfo.fullAccessProfile
      )) {
        
        menu.push({
          label: "Payments",
          path: "/Payments"
        });
        menu.push({
          label: "Homeowners",
          path: "/Homeowners"
        });
        menu.push({
          label: "Payouts",
          path: "/Payouts"
        });
        menu.push({
          label: "View Dashboard Users",
          path: "/DashboardUser"
        });
        if (!userInfo.userCompany || SendPaymentsTo.LockBoxFile === userInfo?.userCompany?.sendPaymentsTo) {
          menu.push({
            label: "Lockbox Files",
            path: "/LockboxFiles"
          });
        }
        menu.push({
          label: "Tax Forms",
          path: "/TaxForms"
        });
      }
      
      if (userInfo.isOnboardUser || userInfo.fullAccessProfile) {
        menu.push({
          label: "Associations onboarding",
          path: "/Onboarding"
        });
      }
      
      if (userInfo.isReportsUser) {
        menu.push({
          label: "Reports",
          path: "/Reports"
        });
      }
      
      
      // Only Onboarding access
      if (userInfo.isOnboardUser
        && !userInfo.fullAccessProfile
        && !userInfo.isReportsUser
        && !userInfo.isDashboardUser) {
        menu = [];
        menu.push({
          label: "Associations onboarding",
          path: "/Onboarding"
        });
      }
      
      // Only Reports access
      if (userInfo.globalReportsEnabled) {
        if (userInfo.isReportsUser
          && userInfo.userCompany?.reportsEnabled
          && !userInfo.isOnboardUser
          && !userInfo.isDashboardUser
          && !userInfo.fullAccessProfile
        ) {
          menu = [];
          menu.push({
            label: "Reports",
            path: "/Reports"
          });
        } else if (userInfo.isFSEmployee) {
          menu.push({
            label: "Reports",
            path: "/Reports"
          });
        }
      }
    }
  } else {
    menu.push({
      label: "Representative Profile",
      path: "/Profile"
    });
  }
  
  const onChangeMenuItem = (path: string) => {
    clearFiltersData()
    history.push(path)
  }
  
  return (
    <React.Fragment>
      {isAuthenticated && (
        <aside className={`sidebar`}>
          <nav>
            <ul>
              {
                menu.map((item: any) => {
                  return <li className={(location.pathname.indexOf(item.path) >= 0) ? 'active' : ''} key={item.path}
                             onClick={() => onChangeMenuItem(item.path)}> {item.label} </li>
                })
              }
            </ul>
          </nav>
        </aside>
      )}
    </React.Fragment>
  );
}

const mapStateToProps = ({userInfo, router}: ApplicationState) => ({
  userInfo,
  location: router.location
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    clearFiltersData: () => {
      switch (window.location.pathname) {
        case '/Payments':
          dispatch(transactionsActions.updateFilter({
            selectedStartDate: moment().startOf('month').toDate(),
            selectedEndDate: moment().endOf('day').toDate(),
            pageNumber: 1,
            pageSize: config.paymentTransactionsPageSize
          }))
          break;
        case '/Homeowners':
          dispatch(homeownersActions.updateFilter({
            pageNumber: 1,
            pageSize: config.homeownersPageSize
          }));
          break;
        case '/Payouts':
          break;
        case '/LockboxFiles':
          break;
        case '/DashboardUser':
          dispatch(updateDashboardUserFilter({
            pageNumber: 1,
            pageSize: config.homeownersPageSize
          }, true));
          break;
        case '/Onboarding':
          break;
        case '/TaxForms':
          break
        case '/Reports':
          break;
        
      }
    }
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type SideMenuProps = ConnectedProps<typeof connector>;

export default connector( SideMenu );
