import * as React from 'react';
import NavMenu from './NavMenu';
import ReleaseNotes from "./ReleaseNotes";
import SideMenu from "./SideMenu";

export default (props: { children?: React.ReactNode }) => (
  <React.Fragment>
    <ReleaseNotes/>
    <NavMenu/>
    <div className="dashboard-container">
      <SideMenu />
      <main style={{marginBottom: "70px", paddingTop: "20px", width: "80%"}}>
        {props.children}
      </main>
    </div>
  </React.Fragment>
);
